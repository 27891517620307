import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "timetable-import-info-api"
    }}>{`Timetable import Info API`}</h1>
    <h2 {...{
      "id": "endpoints"
    }}>{`Endpoints`}</h2>
    <p>{`API for checking the status of timetable data imports.
The endpoint returns the date of the latest successful import of timetable data into Entur's systems for each data provider.
Data providers are identified by their `}<a parentName="p" {...{
        "href": "https://enturas.atlassian.net/wiki/spaces/PUBLIC/pages/637370434/List+of+current+Codespaces"
      }}>{`codespaces`}</a></p>
    <p>{`This import date is also displayed in the list of `}<a parentName="p" {...{
        "href": "/stops-and-timetable-data"
      }}>{`NeTEx files`}</a>{` available on Entur Open Data Portal.
Additionally, the import date is stored in each NeTEx archive available on this page, in the "created" timestamp of the CompositeFrame. For example: created="2019-05-29T12:59:32.202"`}</p>
    <p>{`The date returned by this service is the original import date, i.e. the date Entur received the source dataset from the data provider.
It may be different from the creation date of the archives listed on `}<a parentName="p" {...{
        "href": "/stops-and-timetable-data"
      }}>{`NeTEx files`}</a>{`, since these archives are regenerated every day through a filtering process that removes obsolete data.`}</p>
    <Swagger url="https://api.entur.io/timetable-public/v1/timetable-import-info/swagger.json" baseUrl="https://api.entur.io/timetable-public/v1" mdxType="Swagger" />
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`This API is open under NLOD licence, however, it is required that all consumers identify themselves by using the header ET-Client-Name. Entur will deploy strict rate-limiting policies on API-consumers who do not identify with a header and reserves the right to block unidentified consumers. The structure of ET-Client-Name should be: "company-application"`}</p>
    <p>{`Header examples:
"brakar-journeyplanner"
"fosen_utvikling-departureboard"
"nor_way_bussekspress-nwy-app"`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      